import './App.css';
import { useState} from 'react';
import Footer from './component/Footer';
import Gallery from './component/Gallery';
import assist from './img/assist.png'
import { Route, Routes } from "react-router-dom"
import Practice from './component/Practice';
import Couplegoabroadvisa from './SidebarComponents/Couplegoabroadvisa';
import Courtmarriage from './SidebarComponents/Courtmarriage';
import Govtdocsmarriageregister from './SidebarComponents/Govtdocsmarriageregister';
import Hindimarriage from './SidebarComponents/Hindimarriage';
import NRimarriage from './SidebarComponents/NRimarriage';
import Lovemarriage from './SidebarComponents/Lovemarriage';
import Specialmarriage from './SidebarComponents/Specialmarriage';
import Policeprotectioncourtmarriage from './SidebarComponents/Policeprotectioncourtmarriage';
import Tophead from './component/Tophead';
import ChatBox from './component/Chatbox';
import Model from './component/Model';
import Home from './Pages/Home';
import About from './Pages/About';
import Contactpage from './Pages/Contactpage';
import ServicePage from './Pages/ServicePage';
function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
    const toggleChatBox = () => {
        setIsChatOpen(!isChatOpen);
    };
  return (
    <>
     <Tophead/>
     <Model/> 
     <div style={{paddingTop:"10%"}}>
      <Routes >
        <Route path='/' element={<Home/>} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/aboutus' element={<About/>} />
        <Route path='/services' element={<ServicePage/>} />
        <Route path='/workingarea' element={<Practice />} />
        <Route path='/contact' element={<Contactpage/>} />
        <Route path='/bookappoinment' element={<Contactpage/>} />
        <Route path='/spousevisa' element={<Couplegoabroadvisa />} />
        <Route path='/courtmarriage' element={<Courtmarriage />} />
        <Route path='/marriagebygovt' element={<Govtdocsmarriageregister />} />
        <Route path='/hindumarriage' element={<Hindimarriage />} />
        <Route path='/NRI' element={<NRimarriage />} />
        <Route path='/lovemarriage' element={<Lovemarriage />} />
        <Route path='/specialmarriage' element={<Specialmarriage />} />
        <Route path='/marriagebyprotection' element={<Policeprotectioncourtmarriage />} />
      </Routes>
      </div>
      <Footer />
      <div className="App">
            <button className="chat-toggle-button" onClick={toggleChatBox}>
               <img src={assist} alt="" className='assist' />
            </button>
            <ChatBox isOpen={isChatOpen} onClose={toggleChatBox} />
        </div>
      
    </>
  );
}




export default App;

