import React from 'react'

import Carousal from '../component/Carousal'
import Services from '../component/Services'

function ServicePage() {
  return (
    <div>
        <div className='aboutus'>
            <h1>INSTANTLEGALWEDDINGS</h1>
           <p>
           We help you in Marriage Registration in all our India which is also known as InstantLegalWeddings. With the help of our services couple can apply online for all types of Court Marriages. We ensure that most of the process is completed from the comfort of your home and without any hassles. Our expert team sees to it that all documents are properly filled and filed as per the requirements of concerned officer to avoid any rejection. 
           </p>
        </div>
      <Services/>
      <Carousal/>
     
    </div>
  )
}

export default ServicePage
