import React,{useState,useEffect} from 'react'

function Statistic({companyashtablish , maxNumber,partners, clients}) {
  const [number, setNumber] = useState(0);
  const [company,setcompany]=useState(0);
  const [business,setbusiness] = useState(0);
  const [client, setclient]= useState(0);

  useEffect(() => {
    if (number < maxNumber) {
      const intervalId = setInterval(() => {
        setNumber(prevNumber => prevNumber + 1);
      }, 10); // 
      return () => clearInterval(intervalId);
    }
  }, [number, maxNumber]);

  useEffect(() => {
    if (company < companyashtablish) {
      const intervalId = setInterval(() => {
        setcompany(prevNumber => prevNumber + 1);
      }, 10); 

      return () => clearInterval(intervalId);
    }
  }, [company, companyashtablish]);

  useEffect(() => {
    if (business < partners) {
      const intervalId = setInterval(() => {
        setbusiness(prevNumber => prevNumber + 1);
      }, 10); 

      return () => clearInterval(intervalId);
    }
  }, [business, partners]);

  useEffect(() => {
    if (client < clients) {
      const intervalId = setInterval(() => {
        setclient(prevNumber => prevNumber + 1);
      }, 10); 

      return () => clearInterval(intervalId);
    }
  }, [client, clients]);

  return (
    <section className="statistics">
    <div className="stat">
      <h3>{company}</h3>
      <p>Company Established</p>
    </div>
    <div className="stat">
      <h3>{number}</h3>
      <p>Marriages </p>
    </div>
    <div className="stat">
      <h3>{business}+</h3>
      <p>Business Partners</p>
    </div>
    <div className="stat">
      <h3>{client}</h3>
      <p>Trusting Clients</p>
    </div>
  </section>
  )
}

export default Statistic
