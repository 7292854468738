import React, { useState } from 'react'

function FAQ() {

    const [activeIndex, setActiveIndex] = useState(null);
    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div className="faq-section">
            <h2>FAQ</h2>
            <p>
                We’re proud that our law firm offers top-notch legal services for a nationwide affordable pricing! With us you’ll never feel like the lawyers are just robbers in suits; besides, we win 98% of all cases. So with us, your chances of winning are as high as they possibly can be!
            </p>
            <div className="faq-item" onClick={() => toggleFAQ(0)}>
                <div className="faq-title">
                    <span>{activeIndex === 0 ? '-' : '+'}</span>
                    <h3>What Procedure Of Court Marriage?</h3>
                </div>
                {activeIndex === 0 && (
                    <p className="faq-content">
                        <li>Notice of Intended Marriage: Submit a notice at the Registrar of Marriages in your district, stating your intention to marry.</li>
                        <li>Verification and Waiting Period: After 30 days (to allow for any objections), the details are verified.</li>
                        <li>Marriage Ceremony: If no objections are raised, the marriage can take place in the Registrar's office in the presence of witnesses.</li>
                        <li>Marriage Certificate: After the ceremony, a marriage certificate is issued, completing the legal process.</li>
                    </p>
                )}
            </div>

            <div className="faq-item" onClick={() => toggleFAQ(1)}>
                <div className="faq-title">
                    <span>{activeIndex === 1 ? '-' : '+'}</span>
                    <h3>Required Documents for Court Marriage?</h3>
                </div>
                {activeIndex === 1 && (
                    <p className="faq-content">
                        Documents required for court marriage typically include:
                        <li>Proof of Age: Birth certificate, passport, or school leaving certificate.</li>
                        <li>Identity Proof: Passport, Aadhar card, or voter ID.</li>
                        <li>Address Proof: Passport, utility bill, or driving license.</li>
                        <li>Passport-Sized Photographs: Recent photos of both parties.</li>
                        <li>Proof of Single Status: If previously married, divorce decree or death certificate of spouse.</li>
                    </p>
                )}
            </div>

            <div className="faq-item" onClick={() => toggleFAQ(2)}>
                <div className="faq-title">
                    <span>{activeIndex === 2 ? '-' : '+'}</span>
                    <h3>How Long Does the Court Marriage Process Take?</h3>
                </div>
                {activeIndex === 2 && (
                    <p className="faq-content">
                        The court marriage process generally takes around 30 to 60 days. After submitting the marriage application under the Special Marriage Act, a 30-day notice period is required, allowing any objections. If no objections arise, the marriage officer schedules the marriage. On the day, both parties and three witnesses must be present in court for the final legal formalities.
                    </p>
                )}
            </div>
        </div>
    )
}

export default FAQ
