import React from 'react'
import { Link } from 'react-router-dom';
import toplogo from "../img/flower-bottom.png"
import backgroundImage from "../img/logo.png";
import what from '../img/what.png'
import call from '../img/call.png'
import mail from '../img/mail.png'
function Footer() {
  return (
    <>
      <div className='topfooter'>
        <img src={toplogo} alt="logo" />
        <h1>Subscribe</h1>
        <p>Get 10% off your first purchase when you sign up for our services</p>
        <button  > <a href="tel:+91 92665 58488" className='navlink'>Let's Talk! Schedule a Meeting →</a></button>
      </div>
      <div className='footer'>
        <div className='logo'>
          <img src={backgroundImage} alt="logo" />
        </div>

        <div className='navbackground '>
          <b>MENU</b>
          <Link to="/" className='navlink'>HOME</Link>
          <Link to="/aboutus" className='navlink'>ABOUT</Link>
          <Link to="/gallery" className='navlink'>GALLERY</Link>
          <Link to="/workingarea" className='navlink'>WORKING AREAS</Link>
          <Link to="/services" className='navlink'>SERVICES</Link>
          <Link to="/contact" className='navlink'>CONTACT</Link>
        </div>

        <div className='navbackground'>
          <b>SERVICES</b>
          
          <Link to="/courtmarriage" className='navlink'>Court Marriage</Link>
          <Link to="/lovemarriage" className='navlink'>Love Marriage</Link>
          <Link to="/hindumarriage" className='navlink'>Hindu Marriage</Link>
          <Link to="/specialmarriage" className='navlink'>Special Marriage Act</Link>
          <Link to="/NRI" className='navlink'>NRI Marriage Under Special Marriage Act</Link>
          <Link to="/marriagebygovt" className='navlink'>Marriage Registration for Government Docs</Link>
          <Link to="/marriagebyprotection" className='navlink'>Court Marriage with Police Protection</Link>
         <Link to="/spousevisa" className='navlink'>Couple Go Abroad on Visa by Spouse Visa</Link>
        </div>
        <div className='navbackground'>
          <b>SUBSCRIBE TO OUR INSTANT LEGAL WEDDING</b>
          <br />
          <p>Office Delhi. A1, 332 Sector 6 Rd, Near Shake Karo, Pocket 1, Sector 6A, Rohini, Delhi, 110085</p>
          <b> <a href="tel:+91 92665 58488">+91 92665 58488</a></b>
          <b style={{textDecoration:"underline"}}> <a href="mailto:instantlegalwedding@gmail.com">instantlegalwedding@gmail.com</a> </b>
        
         
          <div className='foot-contact'>
         <a href="tel:+91 92665 58488"><img src={call} alt=""  /></a>
          <a href="mailto:instantlegalwedding@gmail.com"> <img src={mail} alt="" /></a>
          <a href="https://wa.me/9266558488?text=Hello, I want to know more about your services!"
        > <img src={what} alt='.' /></a>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <p>Copyright @2015 instantlegalweddings.com. All Right Reserved</p>
        <p>Privacy Policy</p>
      </div>
    </>
  )
}

export default Footer
