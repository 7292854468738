import React from 'react'
import AboutUs from '../component/AboutUs'
import Statistic from '../component/Statistic'

function About() {
  return (
    <div>
      <AboutUs/>
      <Statistic maxNumber={986} companyashtablish={2015} partners={43} clients={1500} />
      <div className='aboutus'>
        <h1>DISCLAIMER</h1>
        <p>
          instantlegalweddings.com <br /> Have the capacity to Register the all types of Court Marriages from Registrare office and for all types of Court Marriages you can directly contact with us on <a href="tel:+91 92665 58488" className='navlink'>+91 92665 58488</a>
        </p>
      </div>
    </div>
  )
}

export default About
