import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
function Map() {
  return (
    <div className='map'>
      <div className='map1'>
        <MapContainer center={[28.73, 77.11]} zoom={8} style={{ height: "50vh", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            title="newdelhi"
          />
          <Marker position={[28.73, 77.11]} Label={"A"}>
            <Popup>
              LegalBliss<br />Delhi,Rohini.
            </Popup>
          </Marker>
        </MapContainer>
      </div>

      <div className='map2'>

        <p>Office Delhi. A1, 332 Sector 6 Rd, Near Shake Karo, Pocket 1,
          <br /> Sector 6A, Rohini, Delhi, 110085</p>
          
        <b> <a href="tel:+91 92665 58488">+91 92665 58488</a></b>
        <b style={{ textDecoration: "underline" }}>
          <br /> <a href="mailto:instantlegalwedding@gmail.com">instantlegalwedding@gmail.com</a> </b>
      </div>

    </div>
  )
}

export default Map
