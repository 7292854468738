import React from 'react'
import image1 from '../img/1.jpeg'
import image2 from '../img/2.jpeg'
import image3 from '../img/6.png'
import image4 from '../img/7.jpeg'
import image5 from '../img/8.jpeg'
import image6 from '../img/9.jpeg'
import image7 from '../img/13.jpeg'

function Review() {
    const reviews = [
        {
          photo:image1,
          name: "Aarav Sharma",
          rating: 5,
          comment: "Excellent service! Made our marriage registration so easy and hassle-free.",
        },
        {
            photo:image2,
          name: "Neha Patel",
          rating: 4,
          comment: "Great experience overall, quick and professional assistance throughout.",
        },
        {
            photo:image3,
          name: "Rajesh Gupta",
          rating: 5,
          comment: "Highly recommend! The team guided us through every step smoothly.",
        },
        {
            photo:image4,
          name: "Sneha Kapoor",
          rating: 4,
          comment: "Friendly staff and seamless process. Made everything stress-free.",
        },
        {
            photo:image5,
          name: "Vikram Singh",
          rating: 5,
          comment: "Exceptional support! Couldn't have asked for a better service.",
        },
        {
            photo:image6,
          name: "Anjali Menon",
          rating: 4,
          comment: "Prompt responses and easy process. Made our day very special!",
        },
        {
            photo:image7,
          name: "Rahul Deshmukh",
          rating: 5,
          comment: "Very professional and reliable. Would definitely recommend to others.",
        },
      ];
  return (
    <div>
      <div className="reviewsContainer">
      <h2>Customer Reviews</h2>
      <div className="reviewsList">
        {reviews.map((review, index) => (
          <div className="reviewCard" key={index}>
            <img src={review.photo} alt="profile" className='image'/>
            <h3>→{review.name}</h3>
            <p className="rating">Rating: {"⭐".repeat(review.rating)}</p>
            <p>{review.comment}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default Review
