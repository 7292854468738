import React from 'react'
import Content from '../component/Content'
import Eligibilitycriteria from '../component/Eligibilitycriteria'
import Services from '../component/Services'
import Review from '../component/Review'
import Practice from '../component/Practice'
import Contact from '../component/Contact'

function Home() {
  return (
    <div>
      <Content/>
      <Eligibilitycriteria/>
      <Services/>
      <Practice/>
      <Review/>
      <Contact/>
    </div>
  )
}

export default Home
