import React from 'react'
import { Link } from 'react-router-dom';
function Content() {
  
  return (
    <div className='main'>
    <section className="hero">
    <h1><strong>INSTANTLEGALWEDDINGS</strong></h1>
    <p>Your trusted partner in legal matters.</p>
    <button ><Link to="/bookappoinment" className='herobutton'>Book Appoinments</Link></button>
  </section>
    </div>
  )
}

export default Content;
