import React from 'react'
import Contact from '../component/Contact'
import Map from '../component/Map'

function Contactpage() {
  return (
    <div>
      <Contact/>
      <Map/>
    </div>
  )
}

export default Contactpage
