import React, { useState, Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from "../img/logo.png";
import { Link } from 'react-router-dom';
function Navbar() {
  const [active, setActive] = useState("");

  const handleClick = (event) => {
    setActive(event.target.id);

  }
  const [isOpen, setIsOpen] = useState(false);
  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>

      <header className="navbar">
        <nav>
          <ul>
            <div className='logo'>

              <img src={backgroundImage} alt="logo" />
              <br />
            </div>
            <div className='navbar2'> 
            <Link to="/" className='navlink'  >
            <button key={1} className={active === "1" ? "active" : undefined} id={"1"} onClick={handleClick}>HOME</button></Link>
            <Link to="/aboutus" className='navlink'>
            <button key={2} className={active === "2" ? "active" : undefined} id={"2"} onClick={handleClick}>ABOUT</button></Link>
            <Link to="/gallery" className='navlink'>
            <button key={3} className={active === "3" ? "active" : undefined} id={"3"} onClick={handleClick}>GALLERY</button></Link>
            <Link to="/services" className='navlink'>
            <button key={4} className={active === "4" ? "active" : undefined} id={"4"} onClick={handleClick}>SERVICES</button></Link>
            <Link to="/workingarea" className='navlink'> 
            <button key={5} className={active === "5" ? "active" : undefined} id={"5"} onClick={handleClick} >WORKING AREAS</button></Link>
            <Link to="/contact" className='navlink'> 
            <button key={6} className={active === "6" ? "active" : undefined} id={"6"} onClick={handleClick}>CONTACT</button></Link>
           
            <div >
              <div >
                {/* Hamburger Icon */}
                <div className="hamburger" onClick={toggleSidebar}>
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </div>

                {/* Sidebar */}
                <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                  <button className="close-btn" onClick={toggleSidebar}>&times;</button>
                  <ul className="menu">

                    <li><Link to="/courtmarriage" className='humlink'>Court Marriage</Link></li>
                    <li><Link to="/lovemarriage" className='humlink'>Love Marriage</Link></li>
                    <li><Link to="/hindumarriage" className='humlink'>Hindu Marriage</Link></li>
                    <li><Link to="/specialmarriage" className='humlink'>Special Marriage Act</Link></li>
                    <li><Link to="/NRI" className='humlink'>NRI Marriage under Special Marriage Act</Link></li>
                    <li><Link to="/marriagebygovt" className='humlink'>Marriage Registration for Government Docs</Link></li>
                    <li><Link to="/marriagebyprotection" className='humlink'>Court Marriage with Police Protection</Link></li>
                    <li><Link to="/spousevisa" className='humlink'>Couple Go Abroad on Visa by Spouse Visa</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </ul>
        </nav>
      </header>
    </Fragment>
  )
}

export default Navbar
