import React from 'react'
function AboutUs() {
  return (
    <>
      <div className='aboutus'>
        <h1>ABOUT US</h1>
        <p>
          Legal Services in Delhi NCR - Expert Consultation & Support to assist with personalized legal solutions. Call us at -<a href="tel:+91 92665 58488" className='navlink'>+91 92665 58488</a>
        </p>
        <p>10+ years of experience Offering expert legal services with decades of experience in the field.</p>
        <p>Personalized approach Delivering tailored legal advice and representation to meet individual client needs</p>
        <p>We are a group of dedicated individuals committed to providing the best. With years of experience in the industry, we strive to be the best in our field and put our customers first. Our team is passionate about building relationships with each and every one of you and creating an environment where everyone feels welcome. We work hard to ensure that your experience with us is perfect, and we are here to help you. Thank you for choosing us as your go-to provider. We look forward to serving you!</p>
      </div>
     
    </>
  )
}

export default AboutUs
