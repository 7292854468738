import React from 'react'
import { Link } from 'react-router-dom'
function Practice() {
  return (
    <>
   
  
    <section className="practice-areas">
     <div className='wedo'>
     <h2>What We Do</h2>
     <p >We provide services of all Court Marriages like Hindu Marriage, Love Marriage, NRI Marriage , Marriage By Police Protection etc. throughout all India for couples at low price in transparent manner.</p>
     {/* <button class="button" ><span>Hover </span></button> */}
     <button className='boxbutton'> <Link to="/workingarea" className='link'>Read more</Link></button>
     </div>
    <h2>Our Legal Practice Areas</h2>
    <div className="area">
      <h3> <Link to="/spousevisa" className='navlink'>→ Couple Go Abroad on Visa by Spouse Visa</Link></h3>
      <p>Visa Options for Married Couples Planning to Go Abroad</p>
      <button className='boxbutton'> <Link to="/spousevisa" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3> <Link to="/courtmarriage" className='navlink'>→ Court Marriage</Link></h3>
      <p>where two individuals can marry without the need for a religious ceremony.</p>
      <button className='boxbutton'> <Link to="/courtmarriage" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3><Link to="/lovemarriage" className='navlink'>→ Love Marriage</Link></h3>
      <p>The couple decides to marry based on love and personal choice, rather than traditional arranged marriage norms.
      </p>
      <button className='boxbutton'> <Link to="/lovemarriage" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3><Link to="/marriagebyprotection" className='navlink'>→ Court Marriage with Police Protection</Link></h3>
      <p>Understanding the Need for Police Protection in Court Marriages</p>
      <button className='boxbutton'> <Link to="/marriagebyprotection" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3><Link to="/marriagebygovt" className='navlink'>→ Marriage Registration for Government Docs</Link></h3>
      <p>It is a legal acknowledgment of marriage by the government</p>
      <button className='boxbutton'> <Link to="/marriagebygovt" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3><Link to="/NRI" className='navlink'>→ NRI Marriage under Special Marriage Act</Link></h3>
      <p>Couple also marry under the Special Marriage Act, 1954, or choose a court marriage within India.</p>
      <button className='boxbutton'> <Link to="/NRI" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3><Link to="/hindumarriage" className='navlink'>→ Hindu Marriage</Link></h3>
      <p>The Hindu Marriage Act, 1955, outlines the legal requirements for marriage between Hindus in India.</p>
      <button className='boxbutton'> <Link to="/hindumarriage" className='link'>Read more</Link></button>
    </div>
    <div className="area">
      <h3><Link to="/specialmarriage" className='navlink'>→ Special Marriage Act</Link></h3>
      <p>Allows individuals of different religions, castes, or communities to marry without having to convert or perform any religious ceremonies.</p>
      <button className='boxbutton'> <Link to="/specialmarriage" className='link'>Read more</Link></button>
    </div>
  </section>

  </>
  )
}

export default Practice
