import React from 'react'
import Practice from './Practice'
import srevicelogo1 from "../img/ornaments.png"

function Services() {
  return (
    <>
   
    <section className="services-section">
    <h2>Our Legal Services</h2>
    <div className="services">
    <div className="service">
      <img src={srevicelogo1} alt="logo1" />
      <h3>Court Marriages</h3>
      <p>Legal advice for Court Marriages.</p>
    </div>
    <div className="service">
    <img src={srevicelogo1} alt="logo1" />
      <h3>Special Marriages</h3>
      <p>Representing clients in legal disputes.</p>
    </div>
    <div className="service">
    <img src={srevicelogo1} alt="logo1" />
      <h3>Marriage Registration</h3>
      <p>Marriage related cases. & Marriage Registration</p>
    </div>
    </div>
  </section>
  
  </>
  )
}

export default Services;
