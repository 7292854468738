import React from 'react'
import image from '../img/1.jpeg'
import Strategy from './Strategy';
import FAQ from './FAQ';

function Couplegoabroadvisa() {

  return (
    <>
      <div className="family-law-service">
        <section className="intro-section">
          <br />
          <br />
          <h1>Visa Options for Married Couples Planning to Go Abroad</h1>
          <li>Spouse Visa Eligibility:</li>
          <p>
            Genuine Marriage Proof: Most countries require evidence of a genuine marriage, such as a marriage certificate, wedding photos, and joint financial or property records.
          </p>
          <p><li>Minimum Income Requirement: Some countries, like the UK, have a minimum income threshold for the sponsoring partner.</li></p>
          <p><li>Language Proficiency: Certain countries require basic language skills, particularly if you are moving to the EU, the UK, or Australia.</li></p>
          <img src={image} alt="Family" className="intro-image" />

          <h1>Key Requirements for Spouse/Dependent Visas</h1>
          <p><li>Marriage Certificate: A government-issued marriage certificate is mandatory for most spouse visas.</li>
          </p>
          <p>
            <li>Proof of Relationship: Many immigration authorities may require additional proof of a genuine marriage, such as: Photos of the wedding or time spent together. Joint Financial Records: Bank accounts, property deeds, rental agreements, or insurance policies showing both names. Correspondence and Communication: Evidence of ongoing communication (e.g., emails, social media, phone records).</li>
          </p>
        </section>

        <Strategy/>
        <FAQ/>
      </div>
    </>
  )
}

export default Couplegoabroadvisa
